import "./App.css";
import React, { useState } from 'react';
import garoop_kawaii from "../src/img/service/garoopTVicon.png";
import yui from "../src/img/service/yui_lesson.jpg";
import girl from "../src/img/recruit/no1.png";
import boy from "../src/img/recruit/no2.png";
import marry from "../src/img/recruit/no3.png";
import mail2 from "../src/img/info/octicon_mail-16.png";
import google from "../src/img/info/flat-color-icons_google.png";
import twitter1 from "../src/img/info/mdi_twitter.png";
import twitter2 from "../src/img/footer/skill-icons_twitter.png";
import line from "../src/img/footer/icons8-line-48 1.png";
import instagram from "../src/img/footer/skill-icons_instagram.png";
import youtube from "../src/img/footer/logos_youtube-icon.png";
import tiktok from "../src/img/footer/tiktok.png";
import hptitle from "../src/img/first view/hptitle.png";
import { Button } from "@aws-amplify/ui-react";


function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="App">
      <meta name="viewport" content="width=device-width,initial-scale=1"></meta>
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Garoop" />
      <meta
        property="og:description"
        content="株式会社Garoopはカンガルーの会社です。GaroopTVを提供します。ポイントを獲得しながら学校では体験できないことを学ぶことができます。"
      />
      <meta property="og:url" content="https://garoop.jp/" />
      <meta
        property="og:image"
        content="https://garoop-service.s3.ap-northeast-1.amazonaws.com/garoop_logo_service.png"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@tai__yamashita" />
      <meta name="twitter:title" content="Garoop" />
      <meta
        name="twitter:description"
        content="株式会社Garoopはカンガルーの会社です。GaroopTVを提供します。ポイントを獲得しながら学校では体験できないことを学ぶことができます。"
      />
      <meta
        name="twitter:image"
        content="https://garoop-service.s3.ap-northeast-1.amazonaws.com/garoop_logo_service.png"
      />
      <div className="header">
        <div className="pc-menu">
          <div className="logo">
            <img
              className="garoop-icon"
              src="https://garoop-service.s3.ap-northeast-1.amazonaws.com/garoop_logo_service.png"
              width="50"
              height="50"
            ></img>
            <h1><a href="#">Garoop</a></h1>
          </div>
          <div className="header-ul">
            <ul>
              <li>
                <a href="https://create.garoop.jp">GaroopTV</a>
              </li>
              <li>
                <a href="https://create.garoop.jp/plan">サブスクリプション</a>
              </li>
              <li>
                <a href="http://garoop.notion.site">ニュース</a>
              </li>
              <li>
                <a href="#info">お問い合わせ</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="sp-menu">
          <div className="logo">
            <img
              className="garoop-icon"
              src="https://garoop-service.s3.ap-northeast-1.amazonaws.com/garoop_logo_service.png"
              width="50px"
              height="50px"
            ></img>
            <h1><a href="#">Garoop</a></h1>
          </div>
          <button type="button" className={`drawer-btn ${isDrawerOpen ? "open" : ""}`} onClick={toggleDrawer}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div className={`drawer ${isDrawerOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a href="https://create.garoop.jp">GaroopTV</a>
            </li>
            <li>
              <a href="https://create.garoop.jp/plan">サブスクリプション</a>
            </li>
            <li>
              <a href="http://garoop.notion.site">ニュース</a>
            </li>
            <li>
              <a href="#info">お問い合わせ</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="main">
        <div className="firstview-wrapper">
          <div className="firstview">
            <img src={hptitle} className="hptitle"></img>
          </div>
          <div>
            <Button className="garoop-service-component" onClick={() => window.location.href = "https://create.garoop.jp/"}>
              <img className="garuchanIcon" src="https://garoop-service.s3.ap-northeast-1.amazonaws.com/garuchan.png" width={30} height={30}></img>
              GaroopTVへGo
            </Button>
          </div>
          <br></br>
          {/* <br></br>
        <div>
          <a href="https://www.learning-innovation.go.jp/db/ed0238/"><img width={"80%"} height={"70%"} src={futureHomeroom}></img></a>
        </div> */}
        </div>
        <div className="we">
          <div id="we"></div>
          <div className="we-title sect-title">私たちについて</div>
          <div className="we-img">
            <div className="cloud1txt">
              <div className="cloud1explain">
                <div className="cloud1explain-inner">
                  <p>ワクワク楽しく</p>
                  <br />
                  <p>"ガルちゃん"と一緒に動画で学べる</p>
                  <br />
                  <p>TVを</p>
                  <br />
                  <p>絶賛提供中です。</p>
                  <br />
                  <a href="https://create.garoop.jp/">
                    ガルちゃんと学ぶ方はコチラ
                  </a>
                </div>
              </div>
            </div>
            <div className="cloud2txt">
              <div className="cloud2explain">
                <div className="cloud2explain-inner">
                  <p>
                    もっと高度に学びたい方向けに
                    <br />
                    有料プランを行っております。
                  </p>
                  <br />
                  <p>詳しくは下記ご覧ください。</p>
                  <br />
                  <a href="https://create.garoop.jp/plan">
                    有料プランはコチラ
                  </a>
                </div>
              </div>
            </div>
            <div className="cloud3txt">
              <div className="cloud3ttl"></div>
              <div className="cloud3explain">
                <div className="cloud3explain-inner">
                  <p>キャラクター"ガルちゃん"と</p>
                  <br />
                  <p>生成AIを一緒に学ぼう。</p>
                  <br />
                  <p>フォローしてねー！</p>
                  <br />
                  <a href="https://www.tiktok.com/@garoop_official">
                    TikTokはコチラ
                  </a>
                </div>
              </div>
            </div>
            <div className="cloud4txt">
              <div className="cloud4ttl"></div>
              <div className="cloud4explain">
                <div className="cloud4explain-inner">
                  <p>
                    企業の協賛を募集しております。
                    <br className="br-1280" />
                    ブランドUPと広告掲載が可能です。
                  </p>
                  <br />
                  <p>詳しくは下記ご覧ください。</p>
                  <br />
                  <a href="https://garoop.notion.site/Garoop-468a331f5d2649c5ae7163936eecc99a?pvs=4">
                    スポンサーについてみる
                  </a>
                </div>
              </div>
            </div>
            <div className="cloud5txt">
              <div className="cloud5ttl"></div>
              <div className="cloud5explain">
                <div className="cloud5explain-inner">
                  <p>Garoopのチーム紹介です。</p>
                  <br />
                  <a href="https://garoop.notion.site/aaa5ca3a454f4d018bd068eda85b6533">
                    チームメンバーはコチラ
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service">
          <div id="service"></div>
          <div className="service-title sect-title">サービス</div>
          <div className="service-inner">
            <div className="pctxt">
              <div className="pcttl">
                <h3>GaroopTV</h3>
                <span className="pcshadow"></span>
              </div>
              <div className="pcimg">
                <img src={garoop_kawaii} width="250px" height="200" className="pc"></img>
              </div>
              <div className="pcexplain">
                <a href="https://create.garoop.jp/">GaroopTV</a>では生成AI活用スキルやエンジニアリングスキルをワクワク楽しく、リアルタイムで質問しながら動画で学べます！
              </div>
            </div>
            <div className="hptxt">
              <div className="ec1ttl">
                <h3>松下由依アナとコラボ</h3>
                <span className="ec1shadow"></span>
              </div>
              <div className="hpimg">
                <iframe className='video'
                  src={'https://www.youtube.com/embed/fMynk74GtkQ?si=4QEDXiBjhSWmf-3D'}
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="説明動画"
                />
              </div>
              <div className="hpexplain">
                元KBCアナウンサー現在フリーアナウンサー『松下由依』と
                <a href="https://youtu.be/1Ccc_H5Qb10">『AI YUI LESSON』</a>を提供中。ワクワク触れ合えるTVを提供します。
              </div>
              <div className="ec1orange"></div>
            </div>
          </div>
        </div>
        <div className="technology">
          <div id="technology"></div>
          <div className="technology-title sect-title">技術</div>
          <div className="technology-img">
            <div className="technology-grid">
              <div className="circle1">
                <span className="circle1txt">
                  <div className="circle1ttl">インフラ</div>
                  <div className="circle1explain">
                    <ul>
                      <li>AWS全般</li>
                      <li>ECS Fargate</li>
                    </ul>
                  </div>
                </span>
              </div>
              <div className="circle2">
                <span className="circle2txt">
                  <div className="circle2ttl">サーバーサイド</div>
                  <div className="circle2explain">
                    <ul>
                      <li>Go</li>
                      <li>Amazon Elasticache</li>
                      <li>MySQL/Aurora</li>
                      <li>DB</li>
                      <li>Graphql</li>
                      <li>gRPC</li>
                    </ul>
                  </div>
                </span>
              </div>
              <div className="circle3">
                <span className="circle3txt">
                  <div className="circle3ttl">デザイン</div>
                  <div className="circle3explain">
                    <ul>
                      <li>Figma</li>
                    </ul>
                  </div>
                </span>
              </div>
              <div className="circle4">
                <span className="circle4txt">
                  <div className="circle4ttl">ツール</div>
                  <div className="circle4explain">
                    <ul>
                      <li>Slack</li>
                      <li>Notion</li>
                      <li>Miro</li>
                      <li>Zoom</li>
                      <li>Asana</li>
                      <li>Tableau</li>
                    </ul>
                  </div>
                </span>
              </div>
              <div className="circle5">
                <span className="circle5txt">
                  <div className="circle5ttl">フロントエンド</div>
                  <div className="circle5explain">
                    <ul>
                      <li>React</li>
                      <li>Next.js</li>
                      <li>Typescript</li>
                    </ul>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="recruit">
          <div id="recruit"></div>
          <div className="recruit-title sect-title">採用情報</div>
          <div className="recruitttl">職種/募集ポジション</div>
          <div className="recruitttl2">下記は有期雇用契約6ヶ月からスタートです。正社員登用制度もございます。</div>
          <div className="recruit-grid">
            <div className="one">
              <div className="onettl">01</div>
              <div className="onetxt">
                <p>EC販売、経理、バックオフィス</p>
              </div>
              <div className="oneexplain">
                <p>基本給 23万円〜</p>
                <div>ECでの販売訴求を主に実施</div>
                <div>SNSでのキャンペーン、広告運用</div>
                <div>日々の経理</div>
                <div>各種業務のサポート</div>
                <div>雑務</div>
              </div>
            </div>
            <div className="two">
              <div className="twottl">02</div>
              <div className="twotxt">
                <p>デザイナー</p>
              </div>
              <div className="twoexplain">
                <p>基本給 24万円〜</p>
                <div>Figmaでのデザイン開発を主に実施</div>
                <div>設計から開発</div>
                <div>商品をECで販売する上でのSEO周りの実施</div>
              </div>
            </div>
            <div className="three">
              <div className="threettl">03</div>
              <div className="threetxt">
                <p>サーバサイドエンジニア</p>
              </div>
              <div className="threeexplain">
                <p>基本給 26万円〜</p>
                <div>Go言語での開発を主に実施</div>
                <div>Graphqlのサーバサイド</div>
                <div>DB</div>
                <div>各種外部とのAPI</div>
              </div>
            </div>
            <div className="four">
              <div className="fourttl">04</div>
              <div className="fourtxt">
                <p>フロントエンドエンジニア</p>
              </div>
              <div className="fourexplain">
                <p>基本給 25万円〜</p>
                <div>Next.jsでの開発を主に実施</div>
                <div>Graphqlのフロントエンド</div>
                <div>Figmaでデザインした画面の構築、運用</div>
                <div>
                  SREの業務を一部兼務<br className="br-sp"></br>
                  （システムの裏側理解のため）
                </div>
                <div>
                  Amazon Amplify Studioを採用予定のため
                  <br className="br-sp"></br>
                  Next.jsでの<br className="br-pc"></br>フロントの実装は
                  <br className="br-sp"></br>少ないかもしれません
                </div>
              </div>
            </div>
          </div>
          <div className="conditions">
            <div className="treatment">
              <div className="treatment-title">待遇</div>
              <div className="treatmentexplain">
                <ul>
                  <li className="blackcheck">給与改定（年2回）</li>
                  <li className="whitecheck">賞与なし・昇給あり</li>
                  <li className="blackcheck">各種社会保険完備</li>
                  <li className="whitecheck">社員登用制度あり</li>
                </ul>
              </div>
            </div>
            <div className="vacation">
              <div className="vacation-title">休暇</div>
              <div className="vacationexplain">
                <ul>
                  <li className="blackcheck">
                    完全週休2日制（土曜・日曜）<br className="br-sp"></br>
                    ※土日出て平日代替で出勤も可です。<br></br>
                    その場合はslackの勤怠チャンネルに<br className="br-sp"></br>
                    投げるだけでOKです。
                  </li>
                  <li className="whitecheck">祝日</li>
                  <li className="blackcheck">
                    生理休暇（月1日） ※女性の方限定です。<br></br>
                    体調不良と申し出ていただけると<br className="br-sp"></br>
                    月1日自動的に振り当てます。<br></br>
                    生理以外の日に任意で休暇も可です。
                    <br className="br-sp"></br>
                    （恥ずかしいかもしれないので）
                  </li>

                  <li className="whitecheck">夏季休暇（3日）</li>
                  <li className="blackcheck">
                    年末年始休暇（12月29日〜1月3日）
                  </li>
                </ul>
                <div className="vacation3">
                  <ul>
                    <li className="whitecheck">慶弔休暇</li>
                    <li className="blackcheck">産前産後休暇</li>
                    <li className="whitecheck">育児休暇</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="part">
            <div className="partttl">＼アルバイトも募集中／</div>
            <div className="partexplain">
              <div>上記のジュニア採用</div>
              <div>時給1100円〜</div>
              <div>地方どこでも最低時給1100円〜です</div>
              <div>自分自身のパソコンを使用いただきます</div>
            </div>
          </div>
          <div className="point">働くメリットと注意点</div>
          <div className="merit">
            <div className="meritttl">メリット</div>
            <div className="meritexplain">
              <ul>
                <li>フルリモートです。</li>
                <li>
                  正社員採用時にはMacBookを郵送します。
                  <br className="br-sp"></br>5万円ほど負担いただく予定です。
                  <br className="br-sp"></br>(整備済みMacBook Pro16GB
                  M1チップ予定)
                </li>
                <li>
                  地方の方でも東京の給与水準で働くことが
                  <br className="br-sp"></br>できます。
                </li>
                <li>
                  Slack上では絵文字推奨なので、<br className="br-sp"></br>
                  柔らかい雰囲気で気を使うことなく働けます。
                  <br className="br-sp"></br>
                  (会社としても自分に正直な人を採用していきます)
                </li>
                <li>モダンな開発環境、開発内容で開発できます。</li>
                <li>
                  Github Copilot,ChatGPT等を駆使し、<br className="br-sp"></br>
                  AI利用開発をすることができます。
                </li>
                <li>超多彩なことを経験できます。</li>
                <li>
                  希望者にはエンジニアの方でも、<br className="br-sp"></br>
                  営業周りをやっていただきます。
                </li>
                <div className="meritexplain1">
                  (実際に商品を売り、売上を伸ばすことで
                  <br className="br-sp"></br>給料アップをしやすいからです)
                </div>
              </ul>
            </div>
          </div>
          <div className="attention">
            <div className="attentionttl">注意事項</div>
            <div className="attentionexplain">
              <ul>
                <li>
                  会社設立したばかりのスタートアップ企業
                  <br className="br-sp"></br>
                  です。財務が安定しているわけではありません。
                </li>
                <li>見込み残業30時間</li>
                <div className="attentionexplain1">
                  会計上の理由と生産性を上げる理由です。
                  <br className="br-sp"></br>(残業計算がめんどくさいため)
                  <br className="br-sp"></br>実際に働く際は残業基本NGです。
                </div>
                <li>
                  フルリモートで各地から採用するため、
                  <br className="br-sp"></br>
                  対面でのコミュニケーション機会は少ないです。
                </li>
                <div className="attentionexplain1">
                  ※定期的に交流会等は開催予定です。<br className="br-sp"></br>
                  代表が九州出身なので、<br className="br-sp"></br>
                  九州地方の採用が多いと思われます。
                </div>
                <li>オフィスはありません</li>
              </ul>
            </div>
          </div>
          <div className="selectionttl">選考の流れ</div>
          <ol className="selection-flow">
            <li className="selection-step">
              <div className="girltxt selection-stepInner">
                <img
                  src={girl}
                  width="55px"
                  height="180px"
                  className="girl"
                ></img>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdJFmCzgTUBnCOF_fUu9iZ-UIOGR-uJddPUp9jlimu9fQvzJA/viewform">
                  <div className="girlttl">応募</div>
                </a>
                <div className="girlexplain">
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSdJFmCzgTUBnCOF_fUu9iZ-UIOGR-uJddPUp9jlimu9fQvzJA/viewform">
                    <div>Google Form</div>
                  </a>
                  、または<br></br>
                  <a href="https://twitter.com/garoop_company">
                    <div>Twitter DM</div>
                  </a>
                  から応募
                </div>
                <div className="bluecircle1 bluecircle">1</div>
              </div>
            </li>
            <li className="selection-step">
              <div className="boytxt selection-stepInner">
                <img
                  src={boy}
                  width="150px"
                  height="160px"
                  className="boy"
                ></img>
                <div className="boyttl">面接</div>
                <div className="boyexplain">
                  Googleフォームで記入した<br></br>メールアドレス、または
                  <br></br>
                  TwitterのDMでZoomのURLを<br></br>お送りします
                </div>
              </div>
              <div className="bluecircle2 bluecircle">2</div>
            </li>
            <li className="selection-step">
              <div className="marrytxt selection-stepInner">
                <img
                  src={marry}
                  width="129px"
                  height="143px"
                  className="marry"
                ></img>
                <div className="marryttl">採用</div>
                <div className="marryexplain">
                  合格です。おめでとうございます
                </div>
              </div>
              <div className="bluecircle3 bluecircle">3</div>
            </li>
          </ol>
        </div>
        <div className="company">
          <div id="company"></div>
          <div className="company-title sect-title">会社概要</div>
          <div className="company-img">
            <table className="box">
              <tbody>
                <tr>
                  <th>名称</th>
                  <td>株式会社Garoop</td>
                </tr>
                <tr>
                  <th>代表者</th>
                  <td>山下大貴</td>
                </tr>
                <tr>
                  <th>所在地</th>
                  <td>
                    〒850-0862 <br className="br-sp"></br>
                    長崎県長崎市出島町2-11 出島交流会館8F
                  </td>
                </tr>
                <tr>
                  <th>設立</th>
                  <td>2023/04/17</td>
                </tr>
                <tr>
                  <th>決算</th>
                  <td>3月</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="info">
          <div id="info"></div>
          <div className="info-title sect-title">お問い合わせ</div>
          <div className="info-img">
            <p className="infotxt">下記よりお問い合わせください。</p>
            <ul className="info-list">
              <li className="mail2 info-item">
                <a href="mailto:garoop.company@gmail.com">
                  <img src={mail2}></img>
                  <div className="mail2explain info-explain">メール</div>
                </a>
              </li>

              <li className="info-item">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLScAQyFYrJD2ELoDTNyjVhIh3KsMPN35Rm-kpTXcpt06_wQbcA/viewform">
                  <img src={google}></img>
                  <div className="googleexplain info-explain">
                    Googleフォーム
                  </div>
                </a>
              </li>
              <li className="info-item twitter1">
                <a href="https://twitter.com/garoop_company">
                  <img src={twitter1}></img>
                  <div className="twitter1explain info-explain">Twitter</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footerlogo">
          <img
            className="garoop-icon"
            src="https://garoop-service.s3.ap-northeast-1.amazonaws.com/garoop_logo_service.png"
            width="80"
            height="80"
          ></img>
        </div>
        <div className="pc-menu">
          <div className="footerul">
            <ul>
              <li>
                <a href="#we">私たちについて</a>
              </li>
              <li>
                <a href="#service">サービス</a>
              </li>
              <li>
                <a href="#technology">技術</a>
              </li>
              <li>
                <a href="#recruit">採用情報</a>
              </li>
              <li>
                <a href="#company">企業情報</a>
              </li>
              <li>
                <a href="#info">お問い合わせ</a>
              </li>
              <li>
                <a href="">プライバシーポリシー</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="sp-menu">
          <div className="footerdiv">
            <a href="#we">
              <div className="footerwe">私たちについて</div>
            </a>
            <a href="#service">
              <div className="footerservice">サービス</div>
            </a>
            <a href="#technology">
              <div className="footertechnology">技術</div>
            </a>
            <a href="#recruit">
              <div className="footerrecruit">採用情報</div>
            </a>
            <a href="#company">
              <div className="footercompany">企業情報</div>
            </a>
            <a href="#info">
              <div className="footerinfo">お問い合わせ</div>
            </a>
            <a href="">
              <div className="footerprivacy">プライバシーポリシー</div>
            </a>
          </div>
        </div>

        <div className="footerimg">
          <a href="https://x.com/garuchan_garoop">
            <img
              src={twitter2}
              width="24px"
              height="24px"
              className="twitter2"
            ></img>
          </a>
          <a href="">
            <img src={line} width="32px" height="32px" className="line"></img>
          </a>
          <a href="https://www.instagram.com/garoop_gift_shop/">
            <img
              src={instagram}
              width="24px"
              height="24px"
              className="instagram"
            ></img>
          </a>
          <a href="https://www.youtube.com/channel/UCVXDkfy7aD08L7y7JK1AtmA">
            <img
              src={youtube}
              width="35px"
              height="24px"
              className="youtube"
            ></img>
          </a>
          <a href="https://www.tiktok.com/@garoop_official">
            <img
              src={tiktok}
              width="24px"
              height="24px"
              className="tiktok"
            ></img>
          </a>
        </div>

        <div className="footergaroop">@ 2023 Garoop</div>
      </div>
    </div>
  );
}

export default App;
